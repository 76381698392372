* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* body {
  font-family: sans-serif;
} */

header {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
}

/* .nav-area {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
} */

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
}

/* menu on desktop */
.desktop-nav .menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.desktop-nav .menu-items {
  position: relative;
  /* font-size: 14px; */
}

.desktop-nav .menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.loginMsg {
  color: red;
  font-size: medium;
}

.loginSuccMsg {
  color: #07b209;
  font-size: medium;
}

.desktop-nav .menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.desktop-nav button span {
  margin-left: 3px;
}

.desktop-nav .menu-items>a,
.desktop-nav .menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}




.desktop-nav .dropdown {
  position: absolute;
  right: 0;
  left: auto;

  font-size: 0.2rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}

.desktop-nav .dropdown.show {
  display: block;
}

.desktop-nav .dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

.mobile-nav {
  display: none;
}


/* .MuiGrid-item {
  margin: 0;
  box-sizing: border-box;
  margin-left: 25% ;
} */

/* .makeStyles-paper-13 {
  margin-left: 25% !important;
} */

/* menu on mobile */
@media screen and (max-width: 960px) {
/* 
  .MuiGrid-grid-md-5 {
    margin-left: 0% !important;
  } */

  .jss11 {
    padding-left: 0% !important;
    margin-left: 0% !important;
  }
  .jss1 {
    padding-left: 0% !important;
    margin-left: 0% !important;
  }
  .makeStyles-root {
    padding-left: 0% !important;
    margin-left: 0% !important;
  }

  .makeStyles-root-59 {
    margin-left: 0% !important;
    padding-left: 0% !important;
  }

  .makeStyles-root-31 {
    margin-left: 0% !important;
    padding-left: 0% !important;
  }
  .makeStyles-root-21 {
    padding-left: 0% !important;
    margin-left: 0% !important;
  }

  .makeStyles-root-1 {
    margin-left: 0% !important;
    padding-left: 0% !important;
  }

  .makeStyles-root-11 {
    margin-left: 0% !important;
    padding-left: 0% !important;
  }
  .makeStyles-root-65 {
    margin-left: 0% !important;
    padding-left: 0% !important;
  }
.MuiGrid-item {
  margin: 0;
  box-sizing: border-box;
  margin-left: 0% !important;
}

  .nav-area {
    justify-content: space-between;
  }

  .desktop-nav {
    display: none;
    text-align: right;
  }

  .mobile-nav {
    display: block;
  }

  .mobile-nav .mobile-nav__menu-button {
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: relative;
  }

  .mobile-nav .menus {
    list-style: none;
    position: absolute;
    top: 50px;
    right: 20px;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    z-index: 9999;
    min-width: 12rem;
    padding: 0.5rem 0;
    background-color: #fff;
    border-radius: 0.5rem;
  }

  .mobile-nav .menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
  }

  .mobile-nav .menu-items button {
    display: flex;
    align-items: center;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }

  .mobile-nav .menu-items>a,
  .mobile-nav .menu-items button {
    text-align: left;
    padding: 0.7rem 1rem;
  }




  .mobile-nav .dropdown {
    margin-left: 1.2em;
    font-size: 0.9rem;
    padding: 0.5rem 0;
    list-style: none;
    display: none;
  }

  .mobile-nav .dropdown.show {
    display: block;
  }
}

/* page content */
.content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 3rem 20px;
}

.content h1 {
  font-size: 2rem;
}

#error-page {
  /* center content on the page */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 20px;
}



html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4
}





/* html {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5
}

body {
  font-family: inherit;
  line-height: inherit
} */

*,
:after,
:before {
  border: 0 solid;
  box-sizing: border-box
}

hr {
  border-top-width: 1px;
  border-top: 2px solid #beb7b7;
}

img {
  border-style: solid
}







button {
  cursor: pointer
}



table {
  border-collapse: collapse
}

*,
:after,
:before {
  border-color: currentColor
}

[multiple],
[type=date],
[type=datetime-local],
[type=email],
[type=month],
[type=number],
[type=password],
[type=tel],
[type=text],
[type=time],
[type=url],
[type=week],
select,
textarea {
  --tw-shadow: 0 0 #0000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-radius: 0;
  border-width: 1px;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: .5rem .75rem
}

[type=search] {
  --tw-shadow: 0 0 #0000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  /* background-color: #fff; */
  border-color: transparent;
  border-radius: 0;
  border-width: 0px;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: .5rem .75rem;
  color: none;
  border: none;
}

[multiple]:focus,
[type=date]:focus,
[type=datetime-local]:focus,
[type=email]:focus,
[type=month]:focus,
[type=number]:focus,
[type=password]:focus,
[type=tel]:focus,
[type=text]:focus,
[type=time]:focus,
[type=url]:focus,
[type=week]:focus,
select:focus,
textarea:focus {
  --tw-ring-inset: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  border-color: #2563eb;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid transparent;
  outline-offset: 2px
}

[type=search]:focus {
  --tw-ring-inset: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: transparent;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  border-color: transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid transparent;
  outline-offset: 2px
}







a {
  /* --tw-text-opacity: 1; */
  /* color: rgba(0, 155, 170, var(--tw-text-opacity))!important; */
  text-decoration: none
}

[type=date],
[type=datetime-local],
[type=email],
[type=month],
[type=number],
[type=password],
[type=search],
[type=tel],
[type=text],
[type=time],
[type=url],
[type=week],
textarea {
  border-radius: .5rem;
  min-height: 2.5rem
}

[type=date]:focus,
[type=datetime-local]:focus,
[type=email]:focus,
[type=month]:focus,
[type=number]:focus,
[type=password]:focus,
[type=search]:focus,
[type=tel]:focus,
[type=text]:focus,
[type=time]:focus,
[type=url]:focus,
[type=week]:focus,
textarea:focus {
  --tw-border-opacity: 1;
  /* --tw-ring-opacity: 1; */
  --tw-ring-color: rgba(0, 155, 170, var(--tw-ring-opacity));
  border-color: rgba(0, 155, 170, var(--tw-border-opacity))
}

[type=checkbox],
[type=radio] {
  --tw-text-opacity: 1;
  color: rgba(0, 155, 170, var(--tw-text-opacity))
}

[type=checkbox]:focus,
[type=radio]:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 155, 170, var(--tw-ring-opacity))
}

[multiple]:focus,
select:focus {
  --tw-border-opacity: 1;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 155, 170, var(--tw-ring-opacity));
  border-color: rgba(0, 155, 170, var(--tw-border-opacity))
}


/* button {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgba(0, 155, 170, var(--tw-bg-opacity));
  border-radius: .5rem;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  cursor: pointer;
  font-family: AvantGardeGothicITCW02Md, Myriad Pro, Myriad, Sans-serif;
  min-width: 12.5rem;
  padding: .75rem 1rem;
  text-align: left
} */





/* [type=button]:disabled:focus,
[type=button]:disabled:hover,
[type=submit]:disabled:focus,
[type=submit]:disabled:hover,
button.disabled:focus,
button.disabled:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 155, 170, var(--tw-bg-opacity))
} */

[type=search] {
  /* background-image: url(./assets/image/icons/search-grey.svg); */
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
  padding-right: 2.5rem
}

.container {
  width: 100%
}

@media (min-width:640px) {
  .container {
    max-width: 640px
  }
}

@media (min-width:768px) {
  .container {
    max-width: 768px
  }

  .mob-view {
    display: none;
  }
}

@media (min-width:1024px) {
  .container {
    max-width: 1024px
  }
}

.mt-0 {
  margin-top: 0
}

.mt-1 {
  margin-top: .25rem
}

.mt-2 {
  margin-top: .5rem
}

.mt-4 {
  margin-top: 1rem
}

.mt-8 {
  margin-top: 2rem
}

.mt-16 {
  margin-top: 4rem
}

.mr-1 {
  margin-right: .25rem
}

.mr-2 {
  margin-right: .5rem
}

.mr-4 {
  margin-right: 1rem
}

.mr-6 {
  margin-right: 1.5rem
}

.mb-1 {
  margin-bottom: .25rem
}

.mb-2 {
  margin-bottom: .5rem
}

.mb-4 {
  margin-bottom: 1rem
}

.mb-6 {
  margin-bottom: 1.5rem
}

.mb-8 {
  margin-bottom: 2rem
}

.mb-10 {
  margin-bottom: 2.5rem
}

.mb-12 {
  margin-bottom: 3rem
}

.mb-16 {
  margin-bottom: 4rem
}

.ml-2 {
  margin-left: .5rem
}

.ml-1 {
  margin-left: .5rem
}

.ml-8 {
  margin-left: 2rem
}

.ml-10 {
  margin-left: 4rem
}

.ml-15 {
  margin-left: 8rem
}

.ml-50 {
  margin-left: 50%
}

.ml-auto {
  margin-left: auto
}

.ml-logo {
  margin-left: 70rem
}

.profile-fontcolor {
  color: darkblue;
}

.block {
  display: block
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.hidden {
  display: none
}

.clickable {
  cursor: grab;
}

.h-0 {
  height: 0
}

/* .h-4 {
  height: 1rem
} */

.h-6 {
  height: 1.5rem
}

.h-8 {
  height: 2rem
}

.h-10 {
  height: 2.5rem
}

.h-20 {
  height: 5rem
}

.h-24 {
  height: 6rem
}

.h-auto {
  height: auto
}

.max-h-0 {
  max-height: 0
}

.w-0 {
  width: 0
}

.w-4 {
  width: 1rem
}

.w-6 {
  width: 1.5rem
}

.w-8 {
  width: 2rem
}

.w-10 {
  width: 2.5rem
}

.w-12 {
  width: 3rem
}

.w-16 {
  width: 4rem
}

.w-20 {
  width: 5rem
}

.w-28 {
  width: 7rem
}

.w-48 {
  width: 12rem
}

.w-64 {
  width: 16rem
}

.w-1\/2 {
  width: 50%
}

.w-1\/3 {
  width: 33.333333%
}

.w-2\/3 {
  width: 66.666667%
}

.w-3\/4 {
  width: 75%
}

.w-2\/12 {
  width: 16.666667%
}

.w-5\/12 {
  width: 41.666667%
}

.w-full {
  width: 100%
}

.min-w-0 {
  min-width: 0
}

.min-w-38 {
  min-width: 9.5rem
}

.max-w-68 {
  max-width: 17rem
}

.max-w-92 {
  max-width: 23rem
}

.max-w-144 {
  max-width: 36rem
}

.max-w-md {
  max-width: 28rem
}

.border-collapse {
  border-collapse: collapse
}

.p-1 {
  padding: .25rem
}

.p-2 {
  padding: .5rem
}

.p-3 {
  padding: .75rem
}

.p-4 {
  padding: 1rem
}

.p-8 {
  padding: 2rem
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem
}

.py-2 {
  padding-bottom: .5rem;
  padding-top: .5rem
}

.py-4 {
  padding-bottom: 1rem;
  padding-top: 1rem
}

.py-8 {
  padding-bottom: 2rem;
  padding-top: 2rem
}

.pt-4 {
  padding-top: 1rem
}

.pt-6 {
  padding-top: 1.5rem
}

.pt-10 {
  padding-top: 2.5rem
}

.pr-1 {
  padding-right: .25rem
}

.pr-2 {
  padding-right: .5rem
}

.pr-4 {
  padding-right: 1rem
}

.pr-6 {
  padding-right: 1.5rem
}

.pr-8 {
  padding-right: 2rem
}

.pb-2 {
  padding-bottom: .5rem
}

.pb-4 {
  padding-bottom: 1rem
}

.pb-8 {
  padding-bottom: 2rem
}

.pb-12 {
  padding-bottom: 3rem
}

.pb-16 {
  padding-bottom: 4rem
}

.pl-0 {
  padding-left: 0px;
}

.pl-1 {
  padding-left: .25rem
}

.pl-2 {
  padding-left: .5rem
}

.pl-8 {
  padding-left: 2rem
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}




/* .font-AvantGardeGothic-Md {
  font-family: AvantGardeGothicITCW02Md, Myriad Pro, Myriad, Sans-serif!important;
} */

/* .global-txt {
  font-family: AvantGardeGothicITCW01Bk, Myriad Pro, Myriad, Sans-serif !important;
} */

.text-xs {
  font-size: .75rem;
  line-height: 1rem
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem
}

.text-lg {
  font-size: 1.125rem
}

.text-lg,
.text-xl {
  line-height: 1.75rem
}

.text-xl {
  font-size: 1.25rem
}

.text-2xl {
  font-size: 1rem;
  line-height: 2rem
}

.font-light {
  font-weight: 300
}

.font-bold {
  font-weight: 700
}

.uppercase {
  text-transform: uppercase
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity))
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.text-teal {
  --tw-text-opacity: 1;
  color: rgba(0, 155, 170, var(--tw-text-opacity))
}

.text-grey-dark {
  --tw-text-opacity: 1;
  color: rgba(144, 150, 152, var(--tw-text-opacity))
}

.text-brick-70 {
  --tw-text-opacity: 1;
  color: rgba(191, 134, 127, var(--tw-text-opacity))
}

.text-brick-100 {
  --tw-text-opacity: 1;
  color: rgba(164, 82, 72, var(--tw-text-opacity))
}

.text-purple-100 {
  --tw-text-opacity: 1;
  color: rgba(150, 54, 131, var(--tw-text-opacity))
}

.no-underline {
  text-decoration: none
}

/* .hover\:underline:hover {
  text-decoration: underline
} */

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.opacity-0 {
  opacity: 0
}

.opacity-100 {
  opacity: 1
}

.button-class {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgba(0, 155, 170, var(--tw-bg-opacity));
  color: rgba(255, 255, 255, var(--tw-text-opacity));

  min-width: 12.5rem;
  padding: 1rem;
  text-align: left;
}

.navbar {
  overflow: hidden;
  background-color: #2A358C;
}

.profilebar {
  height: 50px !important;
  overflow: hidden;
  background-color: #cfd8ef;
}

.img-height {
  height: 40px;
  margin-bottom: -30px;
}

.profiletext {
  text-align: right !important;
}

/* Navigation links */
.navbar a {
  float: left;
  font-size: 12px;
  color: white;
  text-align: center;
  padding: 2px 10px;
  text-decoration: none;
}

/* The subnavigation menu */
.subnav {
  float: left;
  overflow: hidden;
}

/* Subnav button */
.subnav .subnavbtn {
  font-size: 12px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: sans-serif;
  margin: 0;
}

/* Add a red background color to navigation links on hover */
.navbar a:hover,
.subnav:hover .subnavbtn {
  background-color: #c0d5db;
}

/* Style the subnav content - positioned absolute */
.subnav-content {
  display: none;
  position: absolute;
  left: auto;
  background-color: #f0f4f5;
  width: 100%;
  z-index: 1;
}

/* Style the subnav links */
.subnav-content a {
  float: left;
  color: rgb(16, 14, 14);
  text-decoration: none;
}

/* Add a grey background color on hover */
.subnav-content a:hover {
  background-color: #eee;
  /* color: #bec0d2; */
}

/* When you move the mouse over the subnav container, open the subnav content */
.subnav:hover .subnav-content {
  display: block;
}

.containerDiv {
  width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: left;
  display: inline;
  flex-wrap: wrap;
  justify-content: left;

}


.colordiv {
  width: 100px;
  height: 50px;
  /* margin: 10px; */
  /* display: flex; */
  align-items: center;
  justify-content: center;
  border-radius: 10%;
  color: rgb(73, 69, 205);
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}


.colordivSum {
  border-radius: 10%;
  color: rgba(13, 13, 13, 0.712);
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;

}

.colordivSumHead {

  border-radius: 10%;
  color: rgb(26, 23, 116);
  font-size: 13px;
  font-weight: bold;

}

.pageHeading {
  border-radius: 10%;
  color: rgb(26, 23, 116);
  font-size: 17px;
  font-weight: bold;

}

table {
  border-collapse: collapse;
  text-align: center;
}

tr.border-bottom {
  border-bottom: 1px solid rgb(207, 199, 199) !important;
}

table,
th,
td {
  border: 1px solid rgb(209, 201, 201) !important;
}

.MuiTypography-h5 {
  font-size: 1rem !important;
}

.responsiveTable {
  width: 100% !important;
}

.headingbg-color {
  background-color: #d1d5df;

}

.css-1a4cg4j-MuiButtonBase-root-MuiTab-root {
  min-width: min-content !important;
  font-weight: bold !important;

}

/* .css-1633um2-MuiPaper-root {
  box-shadow: none !important
} */

.css-t89xny-MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}


/* create user  */

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}

.auth-wrapper .form-control:focus {
  border-color: #eff3f8;
  box-shadow: none;
  background-color: #FFFFFF;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.trclick {
  cursor: grab;
}

.css-1i2qv9l-MuiPaper-root {
  box-shadow: none !important
}

.css-1knaqv7-MuiButtonBase-root-MuiButton-root {
  font-size: 1.2rem !important;
}

.css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #2A358C !important;
}


.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
font-weight: bold !important;
}

.css-mh3zap {
font-weight: bold !important;
}

.css-1rtad1 {
  position: sticky !important
}


.css-5bhz76-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
width: 80% !important;
}

.css-1mrn996.Mui-selected {
font-weight: bolder !important;
}

.css-km3p6r-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  background-color: #F6F1F4 !important;
}

.css-cqltog-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  background-color: #F6F1F4 !important;
}

.css-1t54ujf {
  background-color: #F6F1F4 !important;
}

.css-mxxrbr {
  background-color: #F6F1F4 !important;
}

.css-agiett {
  background-color: #F6F1F4 !important;
}

.css-1t5gfdn-MuiPaper-root {
height: 100px !important;
}

.signin {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: -75px; */
}

@media screen and (max-width: 440px) {
.css-6wcx0a-MuiResponsiveChart-container{
  width: 300px !important;
}


.css-1c3bdne {
  width: 300px !important;
} 
 
}

/* for signPDF css */
.react-pdf__Page__textContent {
  display: none !important;
  height: calc(var(--scale-factor)) !important;
}

.react-pdf__Page.pdf-page 
{ 
  display: flex; justify-content: center !important; 
}

.react-pdf__Page__annotations {
  display: none !important;
}

.table-width {
  width: 20%;
}

.table-align {
  text-align: left;
  padding-left: 20px;
}

.css-ypiqx9-MuiDialogContent-root {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  padding: 100px 124px ;
}