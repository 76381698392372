@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap');

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    /* margin: 0; */
    /* padding: 0; */
    /* border: 0; */
    font-size: 12px;

    /* font: inherit; */
    /* vertical-align: baseline; */
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
    background-color: #f6f1f4 !important;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}


body {
    font-family: sans-serif;
}


.app {
    display: flex;
}

.sidebar {
    width: 200px;
    background-color: #365262;
    color: #EBF8FE;
    padding-top: 200px;
    padding-left: 20px;
    height: 100vh;

}

.sidebar__items {
    display: flex;
    flex-direction: column;

}

.sidebar__items a {
    color: #EBF8FE;
    font-size: 20px;
    margin-bottom: 40px;
    text-decoration: none;
}

/* .sidebar_active{
    text-decoration: underline;
} */
.tab-nav {
    margin-top: 70px;
    margin-bottom: 60px;
}

.tab-nav a {
    color: #A2A2A2;
    font-size: 12px;
    font-weight: 500;
    padding: 20px;
    text-decoration: none;
}

.inner-content {
    padding-top: 70px;
    padding-left: 40px;
}

h1 {
    color: #365262;
    font-size: 30px;
    font-weight: 500;
}

h2 {
    color: #365262;
    font-size: 20px;
    font-weight: 500;
}

.login {
    padding-top: 70px;
    padding-left: 40px;
}

/* .login button{
    margin-top:40px;
} */
.logout-button {
    font-size: medium;
}

.login p {
    margin-top: 30px;
}

.tab_active {
    color: #365262 !important;
    /* text-decoration: underline; */
}

.users h1 {
    margin-bottom: 20px;
}

.users__card {
    margin-top: 40px;
    border: 1px solid #365262;
    padding: 20px;
}

.users__card p {
    margin-bottom: 10px;
}

.new-user__form {
    margin-top: 40px;

}

.new-user__form-group {
    margin-top: 10px;
    padding: 10px;
}

.new-user__form-group input {
    margin-left: 20px;
    padding: 10px 10px;
}

/* .new-user__form-group button{
    padding:10px;
} */

.form-row {
    display: flex;
    margin-top: 40px;
    align-items: flex-end;
    margin-bottom: 40px;
}

/* .dynamic-form button {
   padding:10px 10px;
   max-height: 40px;
} */
.input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 10px;

}

.input-group input {
    padding: 10px 10px;
    margin-top: 10px;

}

/* .form-row button {
   padding:10px 10px;
   max-height: 40px;
} */

.row-section {
    margin-top: 40px;
}

.row-section h3 {
    color: #365262;
    margin-top: 30px;
}

.row-section__inner {
    border: 1px solid grey;
    padding: 20px;
    margin-bottom: 20px;
}

.row-section .form-row {
    margin-top: 25px;
}

.row-section .btn-primary {
    margin-top: 20px;
}

.btn-primary {
    background-color: #365262;
    color: white;
}

.top-nav-hide {
    display: none;
    visibility: hidden;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-height: 600px !important;
    max-width: 1500px !important;
}

/* .css-uhb5lp {
    max-height: 200px !important;
    max-width: 1000px !important;
} */

.css-uhb5lp {
    max-width: 1000px !important;
}

.dashboard-color {
    background-color: #F6F1F4;
}

.css-qcqlck-MuiDataGrid-root {
    font-family: sans-serif !important;
}

.css-1l4qfsf-MuiDataGrid-root {
    font-family: sans-serif !important;
}

.css-1a4cg4j-MuiButtonBase-root-MuiTab-root {
    font-size: 12px !important;
    font-family: sans-serif !important;
}


.makeStyles-paper-13 {
    margin: 64px 32px;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: column;
}


.modalContainer {
    position: fixed;
    width: 100vw;
    height: 100%;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal {
    width: 90%;
    max-width: 350px;
    max-height: 200px;
    border: 5px;
    padding: 10px;
    background-color: white;
    box-sizing: border-box;
}


.sigPad__penColors {
    margin-bottom: 10px;
}

.sigPad__penColors p {
    display: inline-block;
    margin-right: 5px;
}

.sigPad__penColors span {
    padding: 0px 9px;
    border-radius: 100%;
    margin-right: 5px;
}

.modal__bottom .create {
    margin-left: 10px;
    background-color: rgb(126, 119, 119);
    color: white;
}

.signature {
    width: 200px;
    height: 70px;
    margin-top: 20px;
    box-shadow: 0 0 4px 2px gainsboro;
    padding: 10px;
    object-fit: contain;
}

.modal {
    display: block !important;
    position: relative !important;
}


/* .MuiGrid-item {
    margin: 0;
    box-sizing: border-box;
    margin-left: 25% !important;
}

@media screen and (max-width: 960px) {

.MuiGrid-grid-md-5 {
    flex-grow: 0;
    max-width: 41.666667%;
    flex-basis: 41.666667%;
}

.MuiGrid-item {
    margin: 0;
    box-sizing: border-box;
    margin-left: 0% !important;
}

} */

.modal-signature-pad .modal {
    max-width: 600px;
    padding: 16px 24px;
    border-radius: 8px;
    max-height: 600px;
  }
  
  .modal-signature-pad .signature-pad {
    border: 2px dashed #c2c2c2;
    background: #f5f4f4;
    margin-bottom: 15px;
    margin-top: 15px;
    border-radius: 8px;
  }
  
  .modal-signature-pad .modal__bottom {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .modal-signature-pad .heading-container h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    color: #323131;
  }
  
  .modal-signature-pad .modal__bottom button {
    text-transform: uppercase;
    border-radius: 8px;
    padding: 8px 26px;
    display: inline-flex;
    margin: 0;
  }