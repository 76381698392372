.gradient-custom {
    /* fallback for old browsers */
    background: #ffffff;
    
    /* Chrome 10-25, Safari 5.1-6 */
    background: #ffffff;
    
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: #ffffff;
    }
    
    .card-registration .select-input.form-control[readonly]:not([disabled]) {
    font-size: 1rem;
    line-height: 2.15;
    padding-left: .75em;
    padding-right: .75em;
    }
    .card-registration .select-arrow {
    top: 13px;
    }

  