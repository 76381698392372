/* * {
    /* margin: 0;
    padding: 0; */
    /* text-align: center; */
    /* text-decoration: none; */
    /* line-height: 60px; */
   
  [role="navigation"] {
    zoom: 1;
    z-index: 1;
    /* line-height: 35px;   */
    text-align: left;
  background-color: #2A358C;
    height: 50px;
  }
  [role="nav"] {  
    z-index: 1;
  }
  [role="navigation"]:before,
  [role="navigation"]:after {
    content: "";
    display: table;
  }
  [role="navigation"]:after {
    clear: both;
  }
  a {
    display: block;
  }
  [role="navigation"] * {
    background: #2A358C;
  }
  [role="navigation"] a {
    color: #fff;
    margin-left: 1rem;
    /* text-shadow: 0 1px 0 #0074b0; */
  }
  [role="navigation"] a:hover,
  [role="navigation"] a:focus {
    background: #2A358C;
  }
  [role="navigation"] > a,
  [role="navigation"] > div {
    float: left;
    /* width: 13.666667%; */
    position: relative;

    float: left;
  font-size: 12px;
  color: white;
  text-align: left;
  padding:1px 4px;
  text-decoration: none;
  line-height: 40px;
  }
  [role="navigation"] > .dropdown > nav {
    display: none;
    position: absolute;
    top: 100%;
    width: 130%;
    margin-left: 1rem;
  }
  [role="navigation"] > .dropdown > nav > .dropdown {
    position: relative;
    
  }
  [role="navigation"] > .dropdown > nav > .dropdown > nav {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    /* width: 100%; */
  }
  [role="navigation"] > .dropdown > nav > .dropdown:hover a {
    background: #2A358C;
   
  }
  [role="navigation"] > .dropdown > nav > .dropdown:hover a:hover {
    background: #2A358C;
  }
  [role="navigation"] > .dropdown > nav > .dropdown:hover > nav {
    display: block;
  }
  [role="navigation"] > .dropdown:hover a {
    background: #2A358C;
   
  }
  [role="navigation"] > .dropdown:hover > nav {
    display: block;
  }
  [role="navigation"] > .dropdown:hover > nav a {
    background: #2A358C;
  }
  [role="navigation"] > .dropdown:hover > nav a:hover {
    background: #2A358C;
  }
  [role="main"] {
    margin: 30px;
    color: #444;
  }
  [role="main"] * {
    text-align: left;
  }
  [role="main"] header {
    margin-bottom: 20px;
  }
  [role="main"] h1 {
    line-height: 32px !important;
    margin-bottom: 5px;
  }
  [role="main"] p {
    line-height: 24px !important;
  }

  .SelectNav {
    width: 120%;
   
  }

  .App-header {
    background-color: #2a358c;
    /* width: 98vw; */
    height: 4vw;
    display: flex;
    align-items: center;
    /* padding: 0 30px; */
    box-sizing: border-box;
  }
  
  .hover-effect {
    margin-right: 8px;
    text-transform: none !important;
    color: white !important;
    font-size: 15px !important;
    text-decoration: none;
    border: none; 
  }

  .hover-noeffect {
    color: white !important;
    font-size: 15px !important;
    text-transform: capitalize !important;
  }

  
  
  .hover-effect:hover {
    border: 1px solid #7484ff !important;
    border-radius: 4px !important;
    background: #7484ff !important;
  }

  .hover-effect:focus,
.hover-effect:active {
  text-decoration: none;
  border: none;          
  outline: none;         
}

  .hover-effect-new {
    /* margin-right: 8px; */
    /* text-transform: capitalize !important; */
    color: white !important;
    font-size: 15px !important;
  }

  .hover-effect-new :hover {
    /* border: 1px solid #7484ff !important; */
    /* border-radius: 4px !important; */
    background: #7484ff !important;
  }
  
  .button-grid {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 10vw;
  }
  
  
  .avatar-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 20px !important;
  }
  
  .avatar-border {
    border: 1px solid white;
    border-radius: 22px;
    margin-right: 16px;
    padding: 2px;
  }
  
  .avatar-border:hover {
    border-radius: 22px;
    background: #7484ff;
  }
  
  .select-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 5vw;
    flex-grow: 1;
  }
  
  .dropdown-container {
    display: inline-block;
    width: 130px; /* Match the width of the DropdownText */
    text-transform: capitalize;
    color: white;
    font-size: 15px;
  }
  
  .dropdown-container:hover {
    /* border: 1px solid #7484ff;
    border-radius: 4px;
    background: #7484ff; */
  }



@media only screen and (max-width: 450px) {
  .App-header {
    background-color: #2a358c;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
  }

  .select-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15vw;
    /* margin-right: 5vw;
    flex-grow: 1; */
  }

  .hover-effect {
    text-transform: capitalize !important;
    color: white !important;
    font-size: 15px !important;
  }

  .button-grid {
    display: flex;
    align-items: center;
    gap: 0px;
    margin-left: 0vw;
  }

  .avatar {
    display: none;
  }

  .avatar-border {
    border: none;
  }
  }


  @media only screen and (min-width: 451px) and (max-width: 850px) {
    .App-header {
      background-color: #2a358c;
      /* width: 98vw; */
      height: auto;
      display: flex;
      align-items: center;
      box-sizing: border-box;
    }

    .button-grid {
      display: flex;
      align-items: center;
      gap: 0px;
      margin-left: 0vw;
    }
  
    .select-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0vw;
      margin-right: 0vw;
    }
  
  
    .avatar {
      width: 30px !important;
      height: 30px !important;
    }
  
    .avatar-container {
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 3vh;
    }

    .hover-effect {
      margin-right: 2px;
    }
    }


  